<template>
  <section class="main-header-wrapper">
    <CNavbar expand="lg">
      <CContainer class="align-items-center">
        <CNavbarBrand
          class="me-0"><router-link @click="closeMenu" :to="{ name: 'home' }" class="d-flex"
            ><img :src="headerlogo" alt="logo" /></router-link
        ></CNavbarBrand>
        <CNavbarToggler
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          @click="Header = !Header"
        />
        <COffcanvas
          id="offcanvasNavbar"
          placement="end"
          :visible="Header"
          @hide="Header = false"
        >
          <COffcanvasHeader>
            <COffcanvasTitle>Offcanvas</COffcanvasTitle>
            <CCloseButton class="text-reset" @click="Header = false" />
          </COffcanvasHeader>
          <COffcanvasBody class="justify-content-center">
            <CNavbarNav>
              <CNavItem>
                <router-link @click="closeMenu" :to="{name:'home'}" class="nav-link">Home</router-link>
              </CNavItem>
              <CNavItem>
                <router-link @click="closeMenu" :to="{name:'categories'}" class="nav-link">Categories</router-link>
              </CNavItem>
              <CNavItem>
                <router-link @click="closeMenu" :to="{name:'how-it-work'}" class="nav-link">how It Work</router-link>
              </CNavItem>
            </CNavbarNav>
          </COffcanvasBody>
        </COffcanvas>
        <div class="profile-dropdown-wrapper">
          <CDropdown direction="center">
            <CDropdownToggle>
                <div class="dropDown-content-wrapper">
                    <div class="dropDown-inner-wrapper">
                        <label><font-awesome-icon icon="fa-solid fa-bars" class="ms-2"/> <img :src="profilePlaceholder" alt="profile" class="ms-3" ></label>
                    </div>
                </div>
            </CDropdownToggle>
            <CDropdownMenu>
              <router-link class="dropdown-item" @click="closeMenu" :to="{name:'sign-in'}">Login</router-link>
              <router-link class="dropdown-item" @click="closeMenu" :to="{name:'sign-up'}">Signup</router-link>
              <CDropdownDivider />
              <router-link class="dropdown-item" @click="closeMenu" :to="{name:'favorite-coaches'}">Favorite</router-link>
              <router-link class="dropdown-item" @click="closeMenu" :to="{name:'how-it-work'}">Help Center</router-link>
              <router-link class="dropdown-item" @click="closeMenu"  :to="{name:'refer-earn'}">Refer & Earn</router-link>
            </CDropdownMenu>
          </CDropdown>
        </div>
      </CContainer>
    </CNavbar>
  </section>
</template>
<script>
import headerlogo from "../assets/images/header/logo.svg";
import profilePlaceholder from "../assets/images/header/profile-placeholder.png";
export default {
  name: "header-component",
  setup() {
    return {
      headerlogo,
      profilePlaceholder
    };
  },
  data() {
    return {
      Header: false,
    };
  },
  methods:{
    closeMenu(){
      this.Header=false;
    }
  }
};
</script>
