import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/SignIn.vue"),
    meta: {
      title: "Sign In",
      hideHeaderFooter: true,
    },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("../views/SignUp.vue"),
    meta: {
      title: "Sign Up",
      hideHeaderFooter: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      title: "Profile",
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/Payment.vue"),
    meta: {
      title: "Payment",
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/ChatRoom.vue"),
    meta: {
      title: "Chat Room",
    },
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("../views/Categories.vue"),
    meta: {
      title: "Categories",
    },
  },
  {
    path: "/category-filter",
    name: "category-filter",
    component: () => import("../views/CategoryFilter.vue"),
    meta: {
      title: "Category Filter",
    },
  },
  {
    path: "/favorite-coaches",
    name: "favorite-coaches",
    component: () => import("../views/FavoriteCoaches.vue"),
    meta: {
      title: "Favorite Coaches",
    },
  },
  {
    path: "/how-it-work",
    name: "how-it-work",
    component: () => import("../views/HowItWork.vue"),
    meta: {
      title: "How It Work",
    },
  },
  {
    path: "/blog-detail",
    name: "blog-detail",
    component: () => import("../views/BlogDetail.vue"),
    meta: {
      title: "Blog Detail",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      title: "Privacy Policy",
    },
  },
  {
    path: "/refer-earn",
    name: "refer-earn",
    component: () => import("../views/Refer.vue"),
    meta: {
      title: "Refer & Earn",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: { name: "dashboard-home" },
    component: () => import("../views/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      hideHeaderFooter: true,
    },
    children: [
      {
        path: "dashboard-home",
        name: "dashboard-home",
        component: () => import("../views/dashboardPages/DBStatistics.vue"),
        meta: {
          title: "DB Statistics",
        },
      },
      {
        path: "earning",
        name: "earning",
        component: () => import("../views/dashboardPages/DBEarning.vue"),
        meta: {
          title: "Earning",
        },
      },
      {
        path: "withdraw",
        name: "withdraw",
        component: () => import("../views/dashboardPages/DBWithdraw.vue"),
        meta: {
          title: "Withdraw Request",
        },
      },
      {
        path: "schedule-appointment",
        name: "schedule-appointment",
        component: () =>
          import("../views/dashboardPages/DBScheduleAppointment.vue"),
        meta: {
          title: "Schedule Appointment",
        },
      },
      {
        path: "account-settings",
        name: "account-settings",
        component: () =>
          import("../views/dashboardPages/DBAccountSettings.vue"),
        meta: {
          title: "Account Settings",
        },
      },
      {
        path: "scheduling",
        name: "scheduling",
        component: () => import("../views/dashboardPages/DBScheduling.vue"),
        meta: {
          title: "Scheduling",
        },
      },
    ],
  },
  {
    path: "/verification",
    name: "verification",
    component: () => import("../views/Verification.vue"),
    meta: {
      title: "Verification",
      hideHeaderFooter: true,
    },
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      title: "Forget Password",
      hideHeaderFooter: true,
    },
  },
  {
    path: "/coach-signup",
    name: "coach-signup",
    component: () => import("../views/CoachSignUp.vue"),
    meta: {
      title: "Coach SignUp",
      hideHeaderFooter: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  // const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  document.title = `${to.meta.title} | HealthBase`;
  next();
});

export default router;
