import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// Vendor Import Global
import CoreuiVue from "@coreui/vue";
import "@coreui/coreui/dist/css/coreui.min.css";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCalendarDays,
  faCheck,
  faEye,
  faEyeSlash,
  faLocationCrosshairs,
  faMagnifyingGlass,
  faChevronDown,
  faCircleChevronRight,
  faCircleChevronLeft,
  faSliders,
  faStar,
  faAnglesRight,
  faLocationDot,
  faEnvelopeOpen,
  faPhone,
  faHeart,
  faCrown,
  faDownload,
  faPaperPlane,
  faHashtag,
  faBusinessTime,
  faPlus,
  faClockRotateLeft,
  faDollarSign,
  faMinus,
  faSackDollar,
  faGear,
  faArrowRightFromBracket,
  faBell,
  faEllipsis,
  faPencil,
  faXmark,
  faCopy

} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedinIn,
  faInstagramSquare,
  faLinkedin
  
} from "@fortawesome/free-brands-svg-icons";
import { faCircleLeft, faCircleRight, faClock, faCommentDots, faEdit, faEnvelope, faImage, faTrashAlt, faUser } from "@fortawesome/free-regular-svg-icons";
library.add(
  faEnvelope,
  faEye,
  faEyeSlash,
  faCheck,
  faBars,
  faLocationCrosshairs,
  faCalendarDays,
  faMagnifyingGlass,
  faChevronDown,
  faCircleChevronRight,
  faCircleChevronLeft,
  faSliders,
  faStar,
  faFacebookSquare,
  faTwitterSquare,
  faLinkedinIn,
  faInstagramSquare,
  faAnglesRight,
  faLinkedin,
  faLocationDot,
  faEnvelopeOpen,
  faPhone,
  faHeart,
  faCrown,
  faDownload,
  faPaperPlane,
  faClock,
  faCommentDots,
  faCircleRight,
  faCircleLeft,
  faUser,
  faEdit,
  faTrashAlt,
  faImage,
  faHashtag,
  faBusinessTime,
  faPlus,
  faClockRotateLeft,
  faDollarSign,
  faMinus,
  faCalendarDays,
  faSackDollar,
  faGear,
  faArrowRightFromBracket,
  faBell,
  faEllipsis,
  faPencil,
  faXmark,
  faCheck,
  faCopy
);
const vueApp = createApp(App);
vueApp.use(store);
vueApp.use(router);
vueApp.use(CoreuiVue);
vueApp.component("font-awesome-icon", FontAwesomeIcon);
vueApp.mount("#app");
