<template>
<section :class="{ 'app-layout-wrapper': true, 'd-flex': !hideHeaderFooter, 'flex-column': !hideHeaderFooter, 'h-100vh': !hideHeaderFooter }">
  <!-- Header Component -->
  <Header v-if="!hideHeaderFooter"></Header>
  <!-- Home Router -->
  <router-view></router-view>
  <!-- Footer Component -->
  <Footer v-if="!hideHeaderFooter"></Footer>
</section>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name:"App-view",
  setup() {
    
  },
  computed: {
    hideHeaderFooter() {
      return this.$route.meta.hideHeaderFooter || false;
    }
  },
  components:{
    Header,
    Footer
  }
}
</script>

<style>
.h-100vh{
  height: 100vh;
}
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box !important;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
    font-family: "Rubik", sans-serif !important;
}
</style>
