<template>
  <footer class="mt-auto">
    <section class="footer-wrapper mt-5">
      <CContainer>
        <CRow class="justify-content-between">
          <CCol lg="4">
            <div class="footer-info-wrapper">
              <img :src="FooterLogo" alt="footer-logo" class="mb-4" />
              <p class="fz-16 fw-600 color-white">
                Health based is not liable for (consequential) damage resulting
                from the use of this site, or from errors or missing
                functionalities on this site.
              </p>
              <ul class="mb-0 sociallinks-list">
                <li>
                  <a href="#"
                    ><span
                      ><font-awesome-icon
                        icon="fa-brands fa-facebook-square" /></span
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><span
                      ><font-awesome-icon
                        icon="fa-brands fa-instagram-square" /></span
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><span
                      ><font-awesome-icon icon="fa-brands fa-linkedin" /></span
                  ></a>
                </li>
                <li>
                  <a href="#"
                    ><span
                      ><font-awesome-icon
                        icon="fa-brands fa-twitter-square" /></span
                  ></a>
                </li>
              </ul>
            </div>
          </CCol>
          <CCol lg="7">
            <CRow>
              <CCol lg="4">
                <div class="footer-link-list">
                  <h4 class="fz-30 color-white fw-600">Specialities</h4>
                  <ul class="mt-4">
                    <li>
                      <router-link
                        :to="{name:'category-filter'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500">Yoga</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                      :to="{name:'category-filter'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Running</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                      :to="{name:'category-filter'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Psychiatrist</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                      :to="{name:'category-filter'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500">Doctor</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                      :to="{name:'category-filter'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500">Dancer</span>
                          </div>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </CCol>
              <CCol lg="4">
                <div class="footer-link-list">
                  <h4 class="fz-30 color-white fw-600">Quick Link</h4>
                  <ul class="mt-4">
                    <li>
                      <router-link
                      :to="{name:'refer-earn'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Refer & Earn</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                      :to="{name:'categories'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Categories</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                      :to="{name:'privacy-policy'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Privacy Policy</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="#"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Contact Us</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{name:'chat'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Chat</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{name:'dashboard'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Dashboard</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        :to="{name:'verification'}"
                        class="d-block footer-links-wrapper text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <div class="footer-link-icon">
                            <span class="color-white"
                              ><font-awesome-icon
                                icon="fa-solid fa-angles-right"
                            /></span>
                          </div>
                          <div class="footer-link-title ms-2">
                            <span class="fz-18 color-white fw-500"
                              >Verification</span
                            >
                          </div>
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </CCol>
              <CCol lg="4">
                <div class="footer-link-list">
                  <h4 class="fz-30 color-white fw-600">Contact Us</h4>
                  <ul class="mt-4">
                    <li>
                      <div class="d-flex align-items-center">
                        <div class="footer-link-icon">
                          <span class="color-white"
                            ><font-awesome-icon icon="fa-solid fa-location-dot"
                          /></span>
                        </div>
                        <div class="footer-link-title ms-2">
                          <span class="fz-18 color-white fw-400"
                            >3556 Beech Street,USA</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div class="footer-link-icon">
                          <span class="color-white"
                            ><font-awesome-icon icon="fa-solid fa-phone"
                          /></span>
                        </div>
                        <div class="footer-link-title ms-2">
                          <span class="fz-18 color-white fw-400"
                            >+1 315 369 5943</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div class="footer-link-icon">
                          <span class="color-white"
                            ><font-awesome-icon
                              icon="fa-solid fa-envelope-open"
                          /></span>
                        </div>
                        <div class="footer-link-title ms-2">
                          <span class="fz-18 color-white fw-400"
                            >Contactus@example.com</span
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CContainer>
      <div class="Copyright-wrapper">
        <CContainer>
          <CRow>
            <CCol lg="6">
              <div class="copyright-content">
                <p class="mb-0 fz-16 color-white fw-400">
                  © 2024 Company. All rights reserved.
                </p>
              </div>
            </CCol>
            <CCol lg="6">
              <div class="text-end">
                <ul class="copyright-nav-list mb-0">
                  <li class="me-3">
                    <router-link to="#" class="d-block fz-16 color-white fw-400 text-decoration-none">Terms and Conditions</router-link>
                  </li>
                  <li>
                    <router-link :to="{name:'privacy-policy'}" class="d-block fz-16 color-white fw-400 text-decoration-none"> Privacy Policy</router-link>
                  </li>
                </ul>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </section>
  </footer>
</template>
<script>
import { CContainer, CRow, CCol } from "@coreui/vue";
import FooterLogo from "../assets/images/app-footer-logo.png";
export default {
  name: "footer-component",
  setup() {
    return {
      FooterLogo,
    };
  },
  components: {
    CContainer,
    CRow,
    CCol,
  },
};
</script>
